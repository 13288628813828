import * as React from 'react';
import { PrismicPreviewProvider } from 'gatsby-source-prismic';

 const repositoryConfigs = [
   {
     repositoryName: "emmim",
     linkResolver: ({ node, key, value }) => doc => {
        return doc;
      },
     componentResolver: {
       page: React.lazy(() => {
        if (typeof window !== 'undefined' && window.__PRISMIC_PREVIEW_DATA__){
          const types = Object.keys(window.__PRISMIC_PREVIEW_DATA__);
          if (types[0] === 'prismicAuthor') {
              return import("./src/templates/AuthorTemplate");
          } else {
              return import("./src/templates/PostTemplate");
          }
        }
        return import("./src/pages/index");
     }),
   },
   },
 ];


export const onServiceWorkerUpdateReady = () => window.location.reload(true);
